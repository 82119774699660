<template>
  <div class="p-fluid formgrid grid tmax" >
    <div class=" field col-12 md:col-12">
      <button class="btn btn-danger" @click="gerarPdf()" style="margin-top:10px;margin-right:5px;"
        title="Gerar Impressão">
        <i class="fa fa-file-pdf-o"></i> Pdf
      </button>

      <button class="btn btn-success" @click="gerarExcel()" style="margin-top:10px;"
        title="Gerar Excel">
        <i class="fa fa-file-excel-o"></i> Excel
      </button>
    </div>
    <div class=" field col-12 md:col-12" id="report-ficha">
      <head>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8">
        <meta charset="utf-8">
      </head>

      <table class="table table-bordered table-remove-border" border=0 cellpadding=5  cellspacing=0 style="font-size: 12pt;font-family: Arial;" >
        <thead>
          <tr>
            <th colspan="10">
              <h2 style="font-size: 18pt;font-family: Arial; text-align: center; text-decoration: underline;"> Ficha Individual </h2>
            </th>
          </tr>
        </thead>
      </table>
      <table class="table table-bordered table-remove-border" border=0 cellpadding=5  cellspacing=0 style="font-size: 12pt;font-family: Arial;" >
        <thead>
          <tr>
            <th align="left" colspan="10"  style="font-weight: 500;">
              Aluno(a): &nbsp;&nbsp; {{ aluno.nome +' '+ aluno.sobrenome}}
            </th>
            <th align="left" colspan="2" style="font-weight: 500;">
              Sexo: &nbsp;&nbsp;	{{ aluno.sexo }}
            </th>
          </tr>

          <tr>
            <th align="left" colspan="10" style="font-weight: 500;">
              Data de nascimento:	&nbsp;&nbsp; {{ aluno.nascimento_format }}
            </th>
            <th align="left" colspan="5" style="font-weight: 500;">
              Naturalidade:	&nbsp;&nbsp; {{ aluno.naturalidade }}
            </th>
          </tr>

          <tr>
            <th align="left" colspan="10" style="font-weight: 500;">
              Pai: &nbsp;&nbsp; {{ aluno.nome_pai }}
            </th>
          </tr>

          <tr>
            <th align="left" colspan="10" style="font-weight: 500;">
              Mãe: &nbsp;&nbsp; {{ aluno.nome_mae }}
            </th>
          </tr>

        </thead>
      </table>

      <br>

      <table class="table table-bordered table-remove-border" border=0 cellpadding=5  cellspacing=0  style="font-size: 12pt;font-family: Arial;">
        <thead>
          <tr>
            <th align="left" colspan="3" style="font-weight: 500;">
              Ano Letivo: &nbsp;&nbsp; {{ aluno.ano }}
            </th>
            <th align="left" colspan="3" style="font-weight: 500;">
              Turma: &nbsp;&nbsp; {{ aluno.turma.nome }}
            </th>
            <th align="left" colspan="2" style="font-weight: 500;">
              N°
            </th>
          </tr>
        </thead>
      </table>

      <br>

      <table class="table table-bordered" border=1 cellpadding=20  cellspacing=20  style="font-size: 12pt;font-family: Arial;" >
        <thead>
          <tr>
            <th colspan="4">Áreas de Conhecimento</th>
            <th colspan="1">1ºB</th>
            <th colspan="1">2ºB</th>
            <th colspan="1">3ºB</th>
            <th colspan="1">4ºB</th>
            <th colspan="2">NF</th>
            <th colspan="3">Obvervações</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="nota in aluno.notas" :key="nota">
            <td align="left" colspan="4">{{ nota.disciplina_nome }}</td>
            <td align="left" colspan="1">{{ nota.periodo_1 }}</td>
            <td align="left" colspan="1">{{ nota.periodo_2 }}</td>
            <td align="left" colspan="1">{{ nota.periodo_3 }}</td>
            <td align="left" colspan="1">{{ nota.periodo_4 }}</td>
            <td align="left" colspan="2"></td>
            <td align="left" colspan="3"></td>
          </tr>
        </tbody>
      </table>

      <br>

      <table class="table table-bordered table-remove-border" border=0 cellpadding=5 cellspacing=10  style="font-size: 12pt;font-family: Arial;">
        <thead>
          <tr>
            <th align="left" colspan="10" style="font-weight: 500;">
              OBS:
            </th>
          </tr>
        </thead>
      </table>


      <table class="table table-bordered table-remove-border" border=0 cellpadding=5 cellspacing=10  style="font-size: 12pt;font-family: Arial;">
        <thead>
          <tr>
            <th align="left" colspan="10" style="font-weight: 500;">
              À vista do(s) resultado(s) o/a aluno(a) foi considerado(a):
            </th>
          </tr>
        </thead>
      </table>


      <table class="table table-bordered table-remove-border" border=0 cellpadding=5 cellspacing=10  style="font-size: 12pt;font-family: Arial;">
        <thead>
          <tr>
            <th align="left" colspan="10" style="font-weight: 500;">
              Data:
            </th>
          </tr>
        </thead>
      </table>


      <table class="table table-bordered table-remove-border" border=0 cellpadding=5 cellspacing=10  style="font-size: 12pt;font-family: Arial;">
        <thead>
          <tr>
            <th align="left" colspan="5" style="font-weight: 500;">
              Supervisão:
            </th>

            <th align="left" colspan="7" style="font-weight: 500;">
              Secretária:
            </th>

            <th align="left" colspan="5" style="font-weight: 500;">
              Diretor(a):
            </th>
          </tr>
        </thead>
      </table>


    </div>
  </div>
</template>

<script>

import { defineComponent } from "vue";
import axios from "axios";

export default defineComponent({
  props: {
    aluno:{},
  },
  data() {
    return {};
  },
  async beforeMount() {},
  methods: {
    gerarPdf(){
      const mywindow = window.open('', 'PRINT', 'height=auto,width=800');

      mywindow.document.write('<html><head><title>ficha individual</title>');
      mywindow.document.write(`
        <style>
          body {
              margin: 0;
              color: #212529;
              /*text-align: center;*/
              background-color: #fff;
          }
          table {
              border-collapse: collapse;
          }
          table tr td, table tr th{
            font-size: 11px !important;
          }
          thead {
            display: table-header-group;
            vertical-align: middle;
            border-color: inherit;
          }
          tr {
            display: table-row;
            vertical-align: inherit;
            border-color: inherit;
          }
          .table-bordered {
              border: 1px solid #dee2e6;
          }
          .table {
              width: 100%;
              margin-bottom: 1rem;
              color: #212529;
          }
          .table-bordered thead td, .table-bordered thead th {
              border-bottom-width: 2px;
          }
          .table thead th {
            vertical-align: bottom;
            border-bottom: 2px solid #dee2e6;
          }
          .table-bordered td, .table-bordered th {
              border: 1px solid #dee2e6;
          }
          .table td, .table th {
            font-size: 11px !important;
          }

          .table td, .table th {
            padding: 0.3rem;
          }

          .table-remove-border {
            border: 0px solid #dee2e6;
          }

          .table-remove-border thead th {
              vertical-align: bottom;
              border-bottom: 0px solid #dee2e6;
              border: 0px !important;
          }

          .table-remove-border > :not(caption) > * {
              border-width: 1px 0;
              border: 0px solid;
          }
        </style>
        `);
      mywindow.document.write('</head><body>');
      mywindow.document.write(`${document.getElementById('report-ficha').innerHTML}`);
      mywindow.document.write('</body></html>');


      mywindow.print();
      mywindow.close();

      return true;
    },
    gerarExcel(e){
      const a = document.createElement('a');
      const data_type = 'data:application/vnd.ms-excel';
      const table_div = document.getElementById('report-ficha');
      //var table_html = table_div.outerHTML.replace(/ /g, '%20');
      const table_html = table_div.outerHTML.replace(/ /g, '%20');
      a.href = data_type + ', ' + table_html;
      a.download = 'fichaindividual.xls';
      a.click();
      /*a.href = data_type + ', ' + table_html;
      a.download = 'fichaindividual.xls';
      a.click();
      e.preventDefault();*/
    }
  },
});
</script>

<style scoped>
body {
  margin: 0;
  color: #212529;
  text-align: center;
  background-color: #fff;
}
table {
    border-collapse: collapse;
}
table tr td, table tr th{
  font-size: 12px !important;
}
thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
}
tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}
.table-bordered {
    border: 1px solid #dee2e6;
}
.table {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
}
.table-bordered thead td, .table-bordered thead th {
    border-bottom-width: 2px;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table-bordered td, .table-bordered th {
    border: 1px solid #dee2e6;
}
.table td, .table th {
  padding: 0.3rem;
}

.table-remove-border {
  border: 0px solid #dee2e6;
}

.table-remove-border thead th {
    vertical-align: bottom;
    border-bottom: 0px solid #dee2e6;
    border: 0px !important;
}

.table-remove-border > :not(caption) > * {
    border-width: 1px 0;
    border: 0px solid;
}

.tmax{
  width: 65% !important;
}

@media  (max-width:1500px) {
  .tmax{
    width: 100% !important;
  }
}

</style>
